import styled from "styled-components"
import { Container } from '@util/standard'
import {
  MOBILE_BREAKPOINT,
  IPAD_BREAKPOINT,
  TABLET_BREAKPOINT
} from "../../util/constants";

export const GalleryContainer = styled(Container)`
  display:block;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    h2{
      text-align:center;
    }
  }
  .light-gallery--warriors{
    width:100%;
    margin-top:30px;
    display:grid;
    grid-template-columns:1fr 1fr 1fr 1fr;
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      grid-template-columns:1fr 1fr 1fr;
    }
    @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
      grid-template-columns:1fr 1fr;
    }
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      grid-template-columns:1fr;
    }
    .lgw-main-src{
      display:block;
      margin:0 15px 15px 0;
      @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
        margin: 0 auto 15px auto;
      }
      .gatsby-image-wrapper{
        height:200px;
        width:200px;
      }
    }
  }
  &:not(:last-child){
    margin-bottom:60px;
  }
`

export const GalleryWrapper = styled.section`
  margin-top:60px;
  margin-bottom:60px;
`