import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Hero from "../components/shared/Hero";
import SEO from "../components/shared/Seo";
import GallerySection from "../components/gallerySection/GallerySection";

export default function Gallery() {
  const { sanityGalleryPage, allSanityGalleries } = useStaticQuery(graphql`
    {
      sanityGalleryPage {
        galleryHero {
          heroText
          heroImage {
            asset {
              fluid {
                srcWebp
                src
                srcSet
                srcSetWebp
              }
              title
            }
          }
        }
        seoBlock {
          pageDescription
          pageKeyWords
          pageTitle
          ogImage {
            asset {
              url
            }
          }
        }
      }
      allSanityGalleries(sort: { fields: order, order: ASC }) {
        nodes {
          images {
            asset {
              fluid {
                srcWebp
                src
                srcSet
                srcSetWebp
                sizes
                base64
                aspectRatio
              }
              title
              _key
            }
            _key
          }
          title
          _key
        }
      }
    }
  `);

  const { galleryHero } = sanityGalleryPage;

  return (
    <>
      <SEO seoData={sanityGalleryPage?.seoBlock} slug="gallery" />
      <Hero heroImage={galleryHero.heroImage} heroText={galleryHero.heroText} />
      <GallerySection data={allSanityGalleries.nodes} />
    </>
  );
}
