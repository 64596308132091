import React from "react";
import { Maybe, SanityGallery } from "@graphql-types";
import Gallery from "./Gallery";
import { GalleryWrapper } from "./gallery.style";

interface Props {
  data: Maybe<Array<Maybe<SanityGallery>>>;
}

function GallerySection({ data }: Props) {
  if (data == null) return null;
  console.log({ data });

  return (
    <GalleryWrapper>
      {data.map((gallery, index) => (
        <Gallery gallery={gallery} key={`${gallery?._key}-${index}`} />
      ))}
    </GalleryWrapper>
  );
}

export default GallerySection;
