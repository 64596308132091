import React from "react";
import { H2 } from "@util/standard";
import { Maybe, SanityGallery } from "@graphql-types";
import { GalleryContainer } from "./gallery.style";
import Img from "gatsby-image";

// Light Gallery
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

interface Props {
  gallery: Maybe<SanityGallery>;
}

function Gallery({ gallery }: Props) {
  if (gallery == null) return null;
  const { title, images } = gallery;

  return (
    <GalleryContainer widthSize="default">
      <H2 fontSize={24}>{title}</H2>

      {images && (
        <LightGallery
          speed={500}
          plugins={[lgThumbnail, lgZoom]}
          elementClassNames="light-gallery--warriors"
        >
          {images.map((image, index) => {
            return (
              <a
                href={image?.asset?.fluid?.src}
                key={`${image?._key}-${index}`}
                className="lgw-main-src"
              >
                <Img fluid={image?.asset?.fluid} />
              </a>
            );
          })}
        </LightGallery>
      )}
    </GalleryContainer>
  );
}

export default Gallery;
